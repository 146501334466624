import apollo from 'graphql/apollo';
import { createInputFieldMutation } from 'graphql/mutations';
import { adminCategoryItemQuery, adminCategoryListQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const createInputField = async ({ categoryId, parentId, parentType, isLibrary, ...props }) =>
  apollo.mutate({
    mutation: createInputFieldMutation,
    variables: { categoryId, parentId, parentType, inputField: props },
    ...(parentType === 'categoryItem' && {
      refetchQueries: [
        {
          query: adminCategoryItemQuery,
          variables: { _id: parentId },
        },
      ],
    }),
    update: (cache, { data }) => {
      try {
        const result = grabFirstGQLDataResult(data);
        const cachedData = cache.readQuery({ query: adminCategoryListQuery, variables: { isLibrary } });
        cache.writeQuery({
          query: adminCategoryListQuery,
          variables: { isLibrary },
          data: {
            categories: cachedData.categories.map((c) =>
              c._id === categoryId ? { ...c, inputFields: [...(c.inputFields || []), result] } : c,
            ),
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  });
export default createInputField;
